/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    h3: "h3",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Sioux Falls, SD, August 23, 2022:"), " ", React.createElement(_components.a, {
    href: "/"
  }, "Prismatic"), ", the embedded integration platform that helps SaaS companies quickly launch ", React.createElement(_components.a, {
    href: "/resources/build-vs-buy-adding-native-integrations-to-your-product/"
  }, "native integrations"), ", announced today that it has appointed Anthony Owens as the company's first chief revenue officer. Owens will lead Prismatic's sales, customer success, and support teams as the company continues its rapid growth."), "\n", React.createElement(_components.p, null, "Prismatic is seeing increased demand for its ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platform"), " (", React.createElement(_components.a, {
    href: "/resources/embedded-ipaas-scalable-integration-strategy/"
  }, "embedded iPaaS"), ") as more SaaS teams look to efficiently deliver quality in-app integrations to the other apps in their customers' ecosystems. The company has outpaced sales and customer acquisition goals, with sustained growth in both new contract and usage revenue. Owens will continue this momentum and scale Prismatic's revenue teams to support further growth."), "\n", React.createElement(_components.p, null, "”We've developed a solid customer base across many SaaS verticals and segments, ranging from startups to mid-market to Fortune 100,” said CEO Michael Zuercher. “Anthony's experience driving growth across all customer segments and deal sizes makes him the perfect leader to spearhead Prismatic's sales and success efforts in this next stage. We're thrilled to have him on board.”"), "\n", React.createElement(_components.p, null, "Owens comes to the company with extensive experience leading and developing sales teams from startups to global operations. Prior to joining Prismatic, he was CRO at LegitScript, where he led all revenue teams for a verification and monitoring platform serving the world's largest search engines, e-commerce platforms, and payment companies."), "\n", React.createElement(_components.p, null, "“This is an ideal time to join Prismatic,” said Owens. “The embedded iPaaS space is really taking off as a new category, separate from traditional iPaaS that serves internal integration needs, and it's growing fast. With a uniquely extensible platform and unrivaled focus on the integration needs specific to SaaS teams, Prismatic's approach has proven to be a winning proposition.”"), "\n", React.createElement(_components.p, null, "Software review site G2 recently ", React.createElement(_components.a, {
    href: "/blog/summer-2022-g2-report-embedded-integration-platforms/"
  }, "named Prismatic a High Performer"), " in its Summer 2022 Grid Report for Embedded Integration Platforms. Prismatic earned the highest user satisfaction score among vendors whose primary product is embedded iPaaS rather than traditional iPaaS, and the third highest satisfaction score in the category overall. The platform holds a 4.9 out of 5 star rating on G2."), "\n", React.createElement(_components.p, null, "“Prismatic is clearly resonating in the market,” said Owens. “I truly believe in the product and look forward to helping the company further accelerate its growth and customer success.”"), "\n", React.createElement(_components.h3, {
    id: "contact",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact",
    "aria-label": "contact permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact"), "\n", React.createElement("p", null, React.createElement(_components.p, null, React.createElement("strong", null, "Prismatic"), " ", React.createElement("br"), "\nBeth Harwood ", React.createElement("br"), "\nVP Marketing & Developer Relations, Cofounder ", React.createElement("br"), "\n888-305-5453 ", React.createElement("br"), "\n", React.createElement(_components.a, {
    href: "mailto:press@prismatic.io",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "press@prismatic.io"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
